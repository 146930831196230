// src/components/JerseyCowTwinAnimation.tsx
import './JerseyCowTwinAnimation.css';

const JerseyCowTwinAnimation = () => {
  return (
    <div className="animation-container">
      <div className="cow-container">
        <img src={require('../assets/JerseyCow.png')} alt="Jersey Cow" className="dna-cow-image" />
      </div>
      <div className="centered-text">
        <div className="top">Capacity</div>
        <div className="flow-line"></div>
        <div className="bottom">Feed Intake</div>
      </div>
      <div className="twin-container">
        <img src={require('../assets/JerseyTwin.png')} alt="Jersey Twin" className="dna-twin-image" />
      </div>
    </div>
  );
};

export default JerseyCowTwinAnimation;
