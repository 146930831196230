// src/components/Footer.tsx
import React from 'react';
import Chatbot from './Chatbot';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* Agri Twin section (Left) */}
          <div className="md:col-span-1">
            <h4 className="font-bold text-green-400">Agri Twin</h4>
            <p className="mt-2">We are transforming agriculture through AI and genomics.</p>
          </div>

          {/* Quick Links section (Center) */}
          <div className="md:col-span-2 text-center md:text-center">
            <h4 className="font-bold text-green-400">Quick Links</h4>
            <ul className="no-bullets mt-2">
              <li><a href="/about" className="hover:underline">About Us</a></li>
              <li><a href="/services" className="hover:underline">Services</a></li>

            </ul>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="text-center mt-8">
          <p>© 2024 AgriTwin.ai. All rights reserved.</p>
        </div>
      </div>

      {/* Floating Chatbot Component */}
      <Chatbot />
    </footer>
  );
};

export default Footer;
