// src/pages/Services.tsx
import React from 'react';
import AgriTwinLogo from '../assets/AgriTwinText.png'; // Adjust path to your logo

import CircularFeedAnimation from '../components/CircularFeedAnimation';
import JerseyCowTwinAnimation from '../components/JerseyCowTwinAnimation';
import './Services.css';

const Services: React.FC = () => {
  return (
    <div className="services">
      <h1>Our Services</h1>
      <div className="service-grid">
        <div className="service-item">
          <h2>Digital Twin Creation</h2>
          <ul className="no-bullets text-white text-center">
            <li>{'At '} <img  src={AgriTwinLogo} alt="Agri Twin Logo" className="agri-twin-logo"/>
              {' '}, we are pioneers in applying digital twin technology to dairy farming. We create a digital replica of each cow in your herd, capturing comprehensive data on their physical attributes, health status, and genetic profile. This digital twin enables real-time monitoring and analysis, allowing us to understand each cow's unique needs and behaviours. By utilising advanced sensors and data collection methods, we provide unparalleled insights that empower you to make informed decisions, optimise care, and enhance productivity across your entire operation.</li>
            <li> <JerseyCowTwinAnimation/></li>
          </ul>
        </div>
        <div className="service-item">
          <h2>Precision Feeding Programs</h2>
          <ul className="no-bullets text-white text-center">
            <li>Our Precision Feeding Programs are meticulously designed to optimise the nutritional intake of each cow based on their requirements. Leveraging data from digital twins and genomic testing, we tailor feed formulations to meet the specific needs of every animal. This ensures that each cow receives the necessary nutrients to reach their full genetic potential. Adopting our precision feeding approach enhances milk production and quality, reduces feed waste, and lowers operational costs, contributing to a more efficient and sustainable farming practice.</li>
            <li><CircularFeedAnimation/></li>
          </ul>
        </div>
        <div className="service-item">
          <h2>Genomic Testing Integration</h2>
          <ul className="no-bullets text-white text-center">
          <li>We integrate cutting-edge genomic testing into our services to unlock the full potential of your herd. We identify key traits such as milk yield potential, disease resistance, and feed efficiency by analysing each cow's genetic makeup. This valuable information allows us to customise feeding and management strategies that align with each animal's genetic strengths and weaknesses. Our genomic insights enable you to make strategic breeding decisions, improve herd health, and ultimately drive higher productivity and profitability on your farm.</li>
            <li><img src={require('../assets/JerseyCowWithGenomics.png')} alt="Jersey Cow Genomics"  className="genomics-image" /></li>
          </ul>
        </div>
        <div className="service-item">
          <h2>Data Analytics & Monitoring</h2>
          <ul className="no-bullets text-white text-center">
          <li>Our Data Analytics & Monitoring service provides real-time insights into your herd's health, productivity, and efficiency. We collect and analyse many data points—including feed intake, milk production, activity levels, and health indicators—to give you a comprehensive view of your operations. Our user-friendly dashboard presents this information in an accessible format, allowing you to track trends, identify potential issues early, and make data-driven decisions. With our analytics, you can continuously optimise your farming practices, enhance animal welfare, and achieve outstanding operational excellence.</li>
          <li>
            <div   className="output-container">
              {/* <h5 className="output-title">Capacity vs Actual Milk Production</h5> */}
              <img src={require('../assets/OutputGraph.png')} alt="Farm Milk Production increase" className="output-graph" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
);
}

export default Services;


