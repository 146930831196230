import React from 'react';
import AgriTwinLogo from '../assets/AgriTwinText.png'; // Adjust path to your logo
import './Sections.css';

const OverviewSection: React.FC = () => {
  return (
    <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <p className="text-xl text-white mb-4 text-center">
            {'At '}
        <img 
          src={AgriTwinLogo} 
          alt="Agri Twin Logo" 
          className="agri-twin-logo"
          />{' '}
          , we redefine dairy farming by integrating cutting-edge technology with agricultural expertise. Our unique approach involves creating digital twins of your cows and precisely measuring each animal's feed requirements based on genomic testing. This allows us to optimise all your assets, from feed crops and land usage to <strong>precision feeding</strong> techniques, ensuring that every resource is utilised to its fullest potential. By harnessing the power of data, we unlock unparalleled efficiency and productivity on your farm, making <img 
          src={AgriTwinLogo} 
          alt="Agri Twin Logo" 
          className="agri-twin-logo"
          />'s <strong>precision agriculture</strong> technology a game-changer for dairy farmers.</p>

          </div>
      </div>
  );
};


const MissionSection: React.FC = () => {
    return (


              <p className="text-xl text-white mb-4 text-center">
              {'At '}
          <img 
            src={AgriTwinLogo} 
            alt="Agri Twin Logo" 
            className="agri-twin-logo"
            />{' '}
          , our mission is to revolutionise dairy farming through the power of advanced technology. We are dedicated to transforming traditional dairy farms into the most efficient and productive operations possible. By creating digital twins of each cow, we precisely measure and meet their unique feed requirements based on genomic testing. This innovative approach allows us to optimise all your assets—from feed crops and land usage to precision feeding techniques—ensuring that every resource is utilised to its fullest potential. We strive to lead the dairy industry into a new era of efficiency and sustainability, offering <strong>smart farming solutions</strong> where technology and agriculture work hand in hand to unlock the full potential of every farm and every cow.</p>
    );
  };
  
// export both components for use in other files
export { MissionSection, OverviewSection };

