import React from 'react';
import Slider from 'react-slick';
import './Carousel.css'; // Add your custom CSS for styling

// Custom Next Arrow
const NextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-next-arrow`}
      onClick={onClick}
    />
  );
};

// Custom Previous Arrow
const PrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-prev-arrow`}
      onClick={onClick}
    />
  );
};

// MemberSlideProps interface
interface MemberSlideProps {
  name: string;
  title: string;
  description: string;
  image: string;
}

// TeamsCarouselProps interface
interface TeamsCarouselProps {
  slides: MemberSlideProps[]; // Expect an array of team members
}

const ImageCarousel: React.FC<TeamsCarouselProps> = ({ slides }) => {
  const settings = {
    dots: false,                 // Disable navigation dots
    infinite: true,              // Infinite loop
    speed: 2000,                 // Transition speed in milliseconds
    slidesToShow: 3,             // Show 3 images at a time
    slidesToScroll: 1,           // Scroll 1 image at a time
    autoplay: true,              // Autoplay
    autoplaySpeed: 2000,         // Autoplay speed
    cssEase: 'linear',           // Smooth linear transition
    rtl: true,                   // Right-to-left scroll
    nextArrow: <NextArrow />,    // Custom next arrow
    prevArrow: <PrevArrow />,    // Custom previous arrow
    responsive: [
      {
        breakpoint: 1400,        // max-width 1400px
        settings: {
          slidesToShow: 3,       // Show 3 images
        },
      },
      {
        breakpoint: 1200,        // max-width 1200px
        settings: {
          slidesToShow: 3,       // Show 3 images
        },
      },
      {
        breakpoint: 768,         // max-width 768px
        settings: {
          slidesToShow: 2,       // Show 2 images
        },
      },
      {
        breakpoint: 468,         // max-width 468px
        settings: {
          slidesToShow: 1,       // Show 1 image
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="carousel-item">
            <img src={slide.image} alt={`slide-${index}`} className="carousel-image" />
            <div className="section-with-bg">
              <div className="content">
                <h3>{slide.name}</h3>
                <h4>{slide.title}</h4>
                <p className='slide-description'>{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
