// pages/Home.tsx
import React, { useEffect, useState } from 'react';
import ImageFade from '../components/ImageFade'; // Import the new component
import { OverviewSection } from '../components/Sections';
import './Home.css'; // Import the CSS for the fade animation

const DigitalTwinHomepage: React.FC = () => {
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    // Start the looped fade animation
    const interval = setInterval(() => {
      setIsFading((prev) => !prev); // Toggle between true and false
    }, 6000); // 4s for fade transition + 2s delay (total 6 seconds)

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="digital-twin-homepage min-h-screen bg-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">

        {/* Image Fade Component */}
        <ImageFade />

        {/* Text Overlay Below Image-Fade-Container */}
        <h1 className="mt-6 text-4xl font-bold text-center text-white">
         Overview
         </h1>
        <OverviewSection /> 
        {/* Additional Sections */}
        <h1 className="mt-6 text-4xl font-bold text-center text-white">
        Benefits
         </h1>

        <h2 className="mt-12 text-4xl font-bold text-center text-white">
        Transformational Efficiency
        </h2>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <ul className="no-bullets text-white text-center">
              <li><strong>Optimise All Assets:</strong> Align your feed crops and land with your herd's precise needs to use them more effectively.</li>
              <li><strong>Precision Feeding:</strong> Tailor feed programs for each cow based on their unique genetic makeup, maximising health and productivity.</li>
              </ul>
          </div>
        </div>

        <h2 className="mt-12 text-4xl font-bold text-center text-white">
        Maximised Productivity
        </h2>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <ul className="no-bullets text-white text-center">
              <li><strong>Unlock Full Potential:</strong> Leverage genomic insights to help each cow achieve its highest possible yield.</li>
              <li><strong>Increase Milk Production:</strong> Experience higher milk outputs through optimised nutrition and care.</li>
              </ul>
          </div>
        </div>


        <h2 className="mt-12 text-4xl font-bold text-center text-white">
        Cost Reduction
        </h2>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <ul className="no-bullets text-white text-center">
              <li><strong>Minimise Waste:</strong> Reduce feed waste by providing nutrition that each cow needs—no more, no less.</li>
              <li><strong>Lower Operational Costs:</strong> Save on expenses related to overfeeding and inefficient resource use.</li>
              </ul>
          </div>
        </div>


        <h2 className="mt-12 text-4xl font-bold text-center text-white">
        Sustainable Farming
        </h2>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <ul className="no-bullets text-white text-center">
              <li><strong>Environmental Stewardship:</strong> Optimise land use and reduce waste, contributing to more sustainable agricultural practices.</li>
              <li><strong>Data-Driven Decisions:</strong> Make informed choices that benefit your farm's ecology and economy.</li>
              </ul>
          </div>
        </div>


        <h2 className="mt-12 text-4xl font-bold text-center text-white">
        Enhanced Animal Health
        </h2>
        <div className="bg-gray-900 shadow-xl rounded-lg overflow-hidden mt-8">
          <div className="p-6">
            <ul className="no-bullets text-white text-center">
              <li><strong>Improved Well-being:</strong> Precision feeding leads to healthier cows with more robust immune systems.</li>
              <li><strong>Early Issue Detection:</strong> Monitor each cow's data in real-time to promptly catch and address health concerns.
              </li>
              </ul>
          </div>
        </div>




        </div>
      </div>
  );
};

const Home: React.FC = () => {
  return (
    <div className="home">
      <DigitalTwinHomepage />
    </div>
  );
};

export default Home;


