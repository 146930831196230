// src/components/CircularFeedAnimation.tsx
import './CircularFeedAnimation.css'; // The CSS containing the keyframe animations

const CircularFeedAnimation = () => {
  return (
    <div className="circular-animation-container">
      <div className="feed-image-wet">
        <img src={require('../assets/1.WetFeed.png')} alt="Wet Feed"  className="feed-image"/>
        <p className="feed-image-text">Wet Feed</p>
      </div>
      <div className="feed-image-dry">
        <img src={require('../assets/2.DryFeed.png')} alt="Dry Feed"  className="feed-image" />
        <p className="feed-image-text">Dry Feed</p>
      </div>
      <div className="feed-image-pellet">
        <img src={require('../assets/3.PelletFeed.png')} alt="Pellet Feed" className="feed-image" />
        <p className="feed-image-text">Stockfeed</p>
      </div>
      <div className="cow-image-container" >
        <img src={require('../assets/JerseyCow.png')} alt="Jersey Cow" className="cow-image"/>
      </div>
    </div>
  );
};

export default CircularFeedAnimation;

