// src/pages/About.tsx
import React from 'react';
import ImageCarousel from '../components/ImageCarousel';
import { MissionSection } from '../components/Sections';
import './About.css';
const slides = [
  {
    name: 'KK Santhanam',
    title: 'Co-Founder & CEO',
    description: "KrishnaKumar Santhanam is a seasoned technocrat with over 20 years of experience in digital marketing, social networking, and new media. As Co-Founder and CEO of Agri Twin, he's revolutionising the agriculture industry by harnessing the power of Artificial Intelligence to create sustainable farming solutions. KK's expertise in AI, AgriTech, and sustainable farming, combined with his robust technical background and project management skills, drives the company's mission to empower farmers with cutting-edge tools and resources. His passion for innovation and commitment to solving real-world challenges in agriculture positions Agri Twin at the forefront of the AI and AgriTech revolution, instilling confidence in our audience about his leadership.",
    image: '/images/KK.png'
  },
  {
    name: 'Lalit Yagnik',
    title: 'Co-Founder & CTO',
    description: "As the Co-Founder and CTO of Agri Twin, Lalit Yagnik is a seasoned technopreneur with over 30 years of experience in digital transformation, solution sales, and technology leadership. His extensive expertise in AI, big data analytics, and cloud technologies, combined with a global perspective, drives Agri Twin's mission to revolutionise the agriculture industry. Lalit's background includes key roles at IBM, where he helped build the Software Group business in Asia Pacific, and leadership positions in various tech startups. His passion for innovation and deep technical knowledge are key to Agri Twin's mission to create cutting-edge AI solutions for sustainable farming.",
    image: '/images/Lalit.png'
  },
  {
    name: 'Bruce Osborne',
    title: 'Farm Advisor',
    description: "Born and raised on a New Zealand Dairy farm, Bruce has managed large-scale dairy farms in New Zealand and Australia for the past 23 years. He intimately understands the challenges and opportunities in the dairy farming industry, making him a strong advocate for fellow farmers. So, he knows the pains of running a dairy farm in this competitive and low-margin market. Bruce has an excellent handle on pasture management and has developed proprietary ways to improve forage and get better profitability per hectare. He is passionate about employing technology to improve farm efficiency for all fellow farmers.",
    image: '/images/Bruce.png'
  },
  {
    name: 'Xin (Shin) Zhang',
    title: 'Solution Architect',
    description: "With a PhD in Computer Science, Xin Zhang brings a unique blend of academic rigour and practical experience to AgriTwin. His data analysis and system design expertise are crucial in developing our digital twin technology for dairy farms. At AgriTwin, Xin leads the technical design of our farm simulation models, ensuring they accurately represent real-world dairy operations. His innovative approach helps farmers leverage advanced technology to improve their operations, from optimising cow health to enhancing overall farm efficiency. Xin's work is instrumental in making AgriTwin's powerful and user-friendly solutions for dairy farmers.",
    image: '/images/Xin.png'
  },
  {
    name: 'Mohan Babu',
    title: 'Data Lead',
    description: "Mohan Babu brings over a decade of expertise in data engineering to AgriTwin, playing a crucial role in transforming complex farm data into actionable insights. With experience across various industries, including finance and telecommunications, Mohan now applies his skills to revolutionise dairy farming. At AgriTwin, he designs and implements the data systems that power our digital twin technology, ensuring that vast amounts of farm information are processed efficiently and accurately. Mohan's dedication to creating user-friendly data solutions is evident in his work, which helps make advanced agricultural technology accessible to farmers, supporting AgriTwin's mission to enhance dairy farm management.",
    image: '/images/Mohan.png'
  },
  // Add more team members here
];

const About: React.FC = () => {
  return (
    <div className="about">
      <section className="mission">
        <h2>Our Mission</h2>
       <MissionSection/>
       </section>
      <section className="team">
        <h2>Our Team</h2>
        <ImageCarousel slides={slides}/>
        
      </section>
      <section className="values">
        <h2>Our Values</h2>
        <ul className="no-bullets text-white text-center">

          <li><strong>Innovation</strong>: Pushing the boundaries of what's possible in AgriTech</li>
          <li><strong>Sustainability</strong>: Promoting environmentally responsible farming practices</li>
          <li><strong>Collaboration</strong>: Partnering with farmers, researchers, and industry leaders</li>
          <li><strong>Integrity</strong>: Maintaining the highest standards of data security and ethical AI use</li>
        </ul>
      </section>
      <section className="achievements">
  <h2>Our Partners</h2>

  <ul className="no-bullets text-white text-center">
  <li>
    <a href="https://www.latrobe.edu.au" target="_blank" rel="noopener noreferrer">
      <img src='/images/LaTrobe.svg' alt='La Trobe University' />
    </a>
  </li>
  <li>
    <a href="https://www.farmers2founders.com" target="_blank" rel="noopener noreferrer">
      <img src='/images/Farmers2Founders.png' alt='Farmers 2 Founders' />
    </a>
  </li>
  <li>
    <a href="https://www.technonjr.org" target="_blank" rel="noopener noreferrer">
      <img src='/images/TechnoNJR.png' alt='Techno NJR' />
    </a>
  </li>
  <li>
    <a href="https://garudaaerospace.com" target="_blank" rel="noopener noreferrer">
      <img src='/images/GarudaAerospace.png' alt='Garuda Aerospace' />
    </a>
  </li>
</ul>
</section>
    </div>
  );
}

export default About;