// pages/ImageFade.tsx
import React, { useEffect, useState } from 'react';
import './ImageFade.css'; // Ensure you have the CSS here or import it globally

const ImageFade: React.FC = () => {
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading((prev) => !prev);
    }, 6000); // 6s interval, adjust this to your preference

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div className="relative image-fade-container">
      {/* Bottom Image (Original) */}
      <img
        src="/OriginalCows.png" // Path to the original image
        alt="Original Cows"
        className={`fade-image bottom-image ${isFading ? 'fade-out' : 'fade-in'}`}
      />

      {/* Top Image (Digital) */}
      <img
        src="/DigitalTwins.png" // Path to the digital twin image
        alt="Digital Twin Cows"
        className={`fade-image top-image ${isFading ? 'fade-in' : 'fade-out'}`}
      />

      {/* Black Sheet Overlay */}
      <div className="black-sheet"></div>

      {/* Static Overlay Content */}
      <div className="overlay-container">
        <div className="overlay-text">Unlock the Full Potential of Your Dairy Cows with Digital Twin Technology.</div>
        <div className="bottom-overlay-text">Precision feeding tailored by genomic insights.</div>
      </div>
    </div>
  );
};

export default ImageFade;
